import React from 'react'
import DetailsSidebar from './ServicesSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const WebdevDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        {/* <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div> */}

                        <div className="services-details-desc pt-100">
                            <span className="sub-title">Application Development</span>
                            <h3>Web/Mobile Development</h3>
                            <p>Our technology enabled services team has wealth of knowledge and experience working with Javascript-based frameworks.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Our Services </h3>
                                        <ul>
                                        <li>Mobile App Development </li>
                                        <li>Web App Development</li>
                                        <li>CI/CD integration</li>
                                        <li>QA Automation</li>
                                        <li>API Integrations</li>
                                        <li>Performance Tuning</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>With our expertise, we can help you to design, build, test and grow your application.</p>
                            <h3>Solutions We Build</h3>

                            <div className="row">

                            <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Mobile Apps
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Headless CMS
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        PWA
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        WayFair
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Amazon
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        WalMart
                                    </div>
                                </div> */}
            
                            </div>

                            <h3>Technologies We Use</h3>
                            <ul className="technologies-features">
                                <li><span>GatsbyJS</span></li>
                                <li><span>ReactJS</span></li>
                                <li><span>Headless CMS</span></li>
                                <li><span>NextJS</span></li>
                                <li><span>NodeJS</span></li>
                                <li><span>MongoDB</span></li>
                            </ul>
                            {/* <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <DetailsSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WebdevDetailsContent